import { createSelector } from "redux-orm";
import orm from "./orm";

export const SelectorAdCostAffiliateNae = createSelector(orm.AdCostAffiliateModel);
export const SelectorAdCostFacebookNae = createSelector(orm.AdCostFacebookModel);
export const SelectorAdCostGoogleNae = createSelector(orm.AdCostGoogleModel);
export const SelectorAdCostTikTokNae = createSelector(orm.AdCostTikTokModel);
export const SelectorFacebookAccountNae = createSelector(orm.FacebookAccountModel);
export const SelectorFacebookS2SNae = createSelector(orm.FacebookS2SModel);
export const SelectorFacebookS2SPixelNae = createSelector(orm.FacebookS2SPixelModel);
export const SelectorGoogleAccountNae = createSelector(orm.GoogleAccountModel);
export const SelectorOrderAdSourceNae = createSelector(orm.OrderAdSourceModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);
export const SelectorTikTokAccountNae = createSelector(orm.TikTokAccountModel);
export const SelectorTiktokS2SNae = createSelector(orm.TiktokS2SModel);
export const SelectorTiktokS2SPixelNae = createSelector(orm.TiktokS2SPixelModel);

export const selectorBySchemaClassName = (schema) => {
        if (schema === 'AdCostAffiliate') {
        return SelectorAdCostAffiliateNae;
    }
        if (schema === 'AdCostFacebook') {
        return SelectorAdCostFacebookNae;
    }
        if (schema === 'AdCostGoogle') {
        return SelectorAdCostGoogleNae;
    }
        if (schema === 'AdCostTikTok') {
        return SelectorAdCostTikTokNae;
    }
        if (schema === 'FacebookAccount') {
        return SelectorFacebookAccountNae;
    }
        if (schema === 'FacebookS2S') {
        return SelectorFacebookS2SNae;
    }
        if (schema === 'FacebookS2SPixel') {
        return SelectorFacebookS2SPixelNae;
    }
        if (schema === 'GoogleAccount') {
        return SelectorGoogleAccountNae;
    }
        if (schema === 'OrderAdSource') {
        return SelectorOrderAdSourceNae;
    }
        if (schema === 'Queue') {
        return SelectorQueueNae;
    }
        if (schema === 'TikTokAccount') {
        return SelectorTikTokAccountNae;
    }
        if (schema === 'TiktokS2S') {
        return SelectorTiktokS2SNae;
    }
        if (schema === 'TiktokS2SPixel') {
        return SelectorTiktokS2SPixelNae;
    }
    }

export const selectorBySchemaSlug = (schema) => {
        if (schema === 'ad-cost-affiliate') {
        return SelectorAdCostAffiliateNae;
    }
        if (schema === 'ad-cost-facebook') {
        return SelectorAdCostFacebookNae;
    }
        if (schema === 'ad-cost-google') {
        return SelectorAdCostGoogleNae;
    }
        if (schema === 'ad-cost-tik-tok') {
        return SelectorAdCostTikTokNae;
    }
        if (schema === 'facebook-account') {
        return SelectorFacebookAccountNae;
    }
        if (schema === 'facebook-s2-s') {
        return SelectorFacebookS2SNae;
    }
        if (schema === 'facebook-s2-s-pixel') {
        return SelectorFacebookS2SPixelNae;
    }
        if (schema === 'google-account') {
        return SelectorGoogleAccountNae;
    }
        if (schema === 'order-ad-source') {
        return SelectorOrderAdSourceNae;
    }
        if (schema === '') {
        return SelectorQueueNae;
    }
        if (schema === 'tik-tok-account') {
        return SelectorTikTokAccountNae;
    }
        if (schema === 'tiktok-s2-s') {
        return SelectorTiktokS2SNae;
    }
        if (schema === 'tiktok-s2-s-pixel') {
        return SelectorTiktokS2SPixelNae;
    }
    }