import { ORM } from "redux-orm";
import AdCostAffiliateModel from "./AdCostAffiliateModel"
import AdCostFacebookModel from "./AdCostFacebookModel"
import AdCostGoogleModel from "./AdCostGoogleModel"
import AdCostTikTokModel from "./AdCostTikTokModel"
import FacebookAccountModel from "./FacebookAccountModel"
import FacebookS2SModel from "./FacebookS2SModel"
import FacebookS2SPixelModel from "./FacebookS2SPixelModel"
import GoogleAccountModel from "./GoogleAccountModel"
import OrderAdSourceModel from "./OrderAdSourceModel"
import QueueModel from "./QueueModel"
import TikTokAccountModel from "./TikTokAccountModel"
import TiktokS2SModel from "./TiktokS2SModel"
import TiktokS2SPixelModel from "./TiktokS2SPixelModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    AdCostAffiliateModel,
    AdCostFacebookModel,
    AdCostGoogleModel,
    AdCostTikTokModel,
    FacebookAccountModel,
    FacebookS2SModel,
    FacebookS2SPixelModel,
    GoogleAccountModel,
    OrderAdSourceModel,
    QueueModel,
    TikTokAccountModel,
    TiktokS2SModel,
    TiktokS2SPixelModel,
);

export default orm;