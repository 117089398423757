import { IAdCostAffiliateFieldsNae } from './useAdCostAffiliateHookNae';
import { IAdCostFacebookFieldsNae } from './useAdCostFacebookHookNae';
import { IAdCostGoogleFieldsNae } from './useAdCostGoogleHookNae';
import { IAdCostTikTokFieldsNae } from './useAdCostTikTokHookNae';
import { IFacebookAccountFieldsNae } from './useFacebookAccountHookNae';
import { IFacebookS2SFieldsNae } from './useFacebookS2SHookNae';
import { IFacebookS2SPixelFieldsNae } from './useFacebookS2SPixelHookNae';
import { IGoogleAccountFieldsNae } from './useGoogleAccountHookNae';
import { IOrderAdSourceFieldsNae } from './useOrderAdSourceHookNae';
import { ITikTokAccountFieldsNae } from './useTikTokAccountHookNae';
import { ITiktokS2SFieldsNae } from './useTiktokS2SHookNae';
import { ITiktokS2SPixelFieldsNae } from './useTiktokS2SPixelHookNae';
import { SelectorAdCostAffiliateNae,SelectorAdCostFacebookNae,SelectorAdCostGoogleNae,SelectorAdCostTikTokNae,SelectorFacebookAccountNae,SelectorFacebookS2SNae,SelectorFacebookS2SPixelNae,SelectorGoogleAccountNae,SelectorOrderAdSourceNae,SelectorTikTokAccountNae,SelectorTiktokS2SNae,SelectorTiktokS2SPixelNae, } from "../models/ormSelectors"
import { NaeSSchemaMap } from '../config/NaeSSchema';
import { store } from '../models/ormstore';

export const getDataCacheForSchema = (className: string) => {
    if (className === NaeSSchemaMap.AdCostAffiliate.className) {
            return {
                dataToCheck: SelectorAdCostAffiliateNae(store.getState()),
                fields: IAdCostAffiliateFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.AdCostFacebook.className) {
            return {
                dataToCheck: SelectorAdCostFacebookNae(store.getState()),
                fields: IAdCostFacebookFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.AdCostGoogle.className) {
            return {
                dataToCheck: SelectorAdCostGoogleNae(store.getState()),
                fields: IAdCostGoogleFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.AdCostTikTok.className) {
            return {
                dataToCheck: SelectorAdCostTikTokNae(store.getState()),
                fields: IAdCostTikTokFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.FacebookAccount.className) {
            return {
                dataToCheck: SelectorFacebookAccountNae(store.getState()),
                fields: IFacebookAccountFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.FacebookS2S.className) {
            return {
                dataToCheck: SelectorFacebookS2SNae(store.getState()),
                fields: IFacebookS2SFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.FacebookS2SPixel.className) {
            return {
                dataToCheck: SelectorFacebookS2SPixelNae(store.getState()),
                fields: IFacebookS2SPixelFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.GoogleAccount.className) {
            return {
                dataToCheck: SelectorGoogleAccountNae(store.getState()),
                fields: IGoogleAccountFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.OrderAdSource.className) {
            return {
                dataToCheck: SelectorOrderAdSourceNae(store.getState()),
                fields: IOrderAdSourceFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.TikTokAccount.className) {
            return {
                dataToCheck: SelectorTikTokAccountNae(store.getState()),
                fields: ITikTokAccountFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.TiktokS2S.className) {
            return {
                dataToCheck: SelectorTiktokS2SNae(store.getState()),
                fields: ITiktokS2SFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.TiktokS2SPixel.className) {
            return {
                dataToCheck: SelectorTiktokS2SPixelNae(store.getState()),
                fields: ITiktokS2SPixelFieldsNae,    
            }
        }
        return undefined;
}