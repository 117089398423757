import { Model, fk, many, attr } from "redux-orm";

class AdCostTikTokModel extends Model {
  static reducer(action, AdCostTikTokModel, session) {
    switch (action.type) {
      case "UPSERT_ad-cost-tik-tok":
        action.payload.forEach((payload) => {
          const dbData = AdCostTikTokModel.withId(payload.id);

          const upsertData = payload;
          if (dbData) {
            const keys = Object.keys(upsertData);
            let needChange = false;
            keys.map((key) => {
              if (
                JSON.stringify(upsertData[key]) !== JSON.stringify(dbData[key])
              ) {
                needChange = true;
              }
            });

            if (!needChange) {
              return;
            }
          }

          AdCostTikTokModel.upsert(payload);
        });
        break;
    }
    // Return value is ignored.
    return session.state;
  }

  toString() {
    return this.id;
  }
  // Declare any static or instance methods you need.
}
AdCostTikTokModel.modelName = "AdCostTikTokModel";

AdCostTikTokModel.fields = {
  id: attr(),
};

export default AdCostTikTokModel;