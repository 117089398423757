
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import moment from "moment";
import { SelectorAdCostAffiliateNae, SelectorAdCostFacebookNae, SelectorAdCostGoogleNae, SelectorAdCostTikTokNae, SelectorFacebookAccountNae, SelectorFacebookS2SNae, SelectorFacebookS2SPixelNae, SelectorGoogleAccountNae, SelectorOrderAdSourceNae, SelectorTikTokAccountNae, SelectorTiktokS2SNae, SelectorTiktokS2SPixelNae } from '../models/ormSelectors';
import { useAdCostAffiliateHookNae } from '../hooks/useAdCostAffiliateHookNae';
import { useAdCostFacebookHookNae } from '../hooks/useAdCostFacebookHookNae';
import { useAdCostGoogleHookNae } from '../hooks/useAdCostGoogleHookNae';
import { useAdCostTikTokHookNae } from '../hooks/useAdCostTikTokHookNae';
import { useFacebookAccountHookNae } from '../hooks/useFacebookAccountHookNae';
import { useFacebookS2SHookNae } from '../hooks/useFacebookS2SHookNae';
import { useFacebookS2SPixelHookNae } from '../hooks/useFacebookS2SPixelHookNae';
import { useGoogleAccountHookNae } from '../hooks/useGoogleAccountHookNae';
import { useOrderAdSourceHookNae } from '../hooks/useOrderAdSourceHookNae';
import { useTikTokAccountHookNae } from '../hooks/useTikTokAccountHookNae';
import { useTiktokS2SHookNae } from '../hooks/useTiktokS2SHookNae';
import { useTiktokS2SPixelHookNae } from '../hooks/useTiktokS2SPixelHookNae';


export const useEmptyHook = (id: number) => {
            return undefined;
        }

export const getHookForSchema = (schema: string) => {
  let selector : any = useEmptyHook;
  if (schema === 'ad-cost-affiliate') {
    return useAdCostAffiliateHookNae;
  }
  if (schema === 'ad-cost-facebook') {
    return useAdCostFacebookHookNae;
  }
  if (schema === 'ad-cost-google') {
    return useAdCostGoogleHookNae;
  }
  if (schema === 'ad-cost-tik-tok') {
    return useAdCostTikTokHookNae;
  }
  if (schema === 'facebook-account') {
    return useFacebookAccountHookNae;
  }
  if (schema === 'facebook-s2-s') {
    return useFacebookS2SHookNae;
  }
  if (schema === 'facebook-s2-s-pixel') {
    return useFacebookS2SPixelHookNae;
  }
  if (schema === 'google-account') {
    return useGoogleAccountHookNae;
  }
  if (schema === 'order-ad-source') {
    return useOrderAdSourceHookNae;
  }
  if (schema === 'tik-tok-account') {
    return useTikTokAccountHookNae;
  }
  if (schema === 'tiktok-s2-s') {
    return useTiktokS2SHookNae;
  }
  if (schema === 'tiktok-s2-s-pixel') {
    return useTiktokS2SPixelHookNae;
  }
  return selector;
}

export function AdCostAffiliateEmptyFieldNae(props: PropsLink) {
  const element = useAdCostAffiliateHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function AdCostFacebookEmptyFieldNae(props: PropsLink) {
  const element = useAdCostFacebookHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function AdCostGoogleEmptyFieldNae(props: PropsLink) {
  const element = useAdCostGoogleHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function AdCostTikTokEmptyFieldNae(props: PropsLink) {
  const element = useAdCostTikTokHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function FacebookAccountEmptyFieldNae(props: PropsLink) {
  const element = useFacebookAccountHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function FacebookS2SEmptyFieldNae(props: PropsLink) {
  const element = useFacebookS2SHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function FacebookS2SPixelEmptyFieldNae(props: PropsLink) {
  const element = useFacebookS2SPixelHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function GoogleAccountEmptyFieldNae(props: PropsLink) {
  const element = useGoogleAccountHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function OrderAdSourceEmptyFieldNae(props: PropsLink) {
  const element = useOrderAdSourceHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function TikTokAccountEmptyFieldNae(props: PropsLink) {
  const element = useTikTokAccountHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function TiktokS2SEmptyFieldNae(props: PropsLink) {
  const element = useTiktokS2SHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function TiktokS2SPixelEmptyFieldNae(props: PropsLink) {
  const element = useTiktokS2SPixelHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

